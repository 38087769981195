import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { reset } from 'numeral';

import { history, fetchWrapper } from '../helpers';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: null,
        error: null,
        userLoading: true,
        dayCost: 0,
        halfDayCost: 0
    }
}

function createReducers() {
    return{
      deleteMessage,
      getCost
    }
    function deleteMessage(state){
      state.error= null;
    }
    function getCost(state,action){
      state.dayCost= action.payload.dayCost;
      state.halfDayCost= action.payload.halfDayCost;
      state.nonAdherentCost= action.payload.nonAdherentCost;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

    return {
        login: login(),
        refreshToken: refreshToken(),
        logout: logout(),
        reset: reset(),
        updatepass: updatepass()
    };    

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async ({ email, password }) => fetchWrapper.post(`${baseUrl}/user`, { email, password })
        );
    }
    function refreshToken() {
      return createAsyncThunk(
          `${name}/refreshToken`,
          async () => fetchWrapper.post(`${baseUrl}/user/refresh`)
      );
    }
    function logout() {
      return createAsyncThunk(
          `${name}/logout`,
          async (user) => fetchWrapper.post(`${baseUrl}/user/logout/${user}`)
      );
    }
    function reset() {
      return createAsyncThunk(
          `${name}/reset`,
          async ({ email }) => fetchWrapper.post(`${baseUrl}/user/reset-password`,{ email })
      );
    }
    function updatepass() {
      return createAsyncThunk(
          `${name}/updatepass`,
          async ({ token, password }) => fetchWrapper.post(`${baseUrl}/user/update-password`,{ token, password })
      );
    }
}

function createExtraReducers() {
    return {
        ...login(),
        ...refreshToken(),
        ...logout(),
        ...reset(),
        ...updatepass(),
    };

    function login() {
        const { pending, fulfilled, rejected } = extraActions.login;
        return {
            [pending]: (state) => {
                state.error = null;
                state.userLoading = true;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // localStorage.setItem('user', JSON.stringify(user));
                state.user = user;

                // get return url from location state or default to home page
                const { from } = history.location.state || { from: { pathname: '/' } };
                history.navigate(from, { replace: true });
                state.userLoading = false;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.userLoading = false;
            }
        };
    }
    function refreshToken() {
      const { pending, fulfilled, rejected } = extraActions.refreshToken;
      return {
          [pending]: (state) => {
              state.error = null;
              state.userLoading = true;
          },
          [fulfilled]: (state, action) => {
              const user = action.payload;
              state.user = user;
              state.userLoading = false;
          },
          [rejected]: (state, action) => {
              state.error = action.error;
              state.userLoading = false;
          }
      };
    }
    function logout() {
      const { pending, fulfilled, rejected } = extraActions.logout;
      return {
          [pending]: (state) => {
              state.error = null;
          },
          [fulfilled]: (state) => {
            state.user = null;
            history.navigate('/login',{ replace: true });
          },
          [rejected]: (state, action) => {
              state.error = action.error;
          }
      };
    }
    function reset() {
      const { pending, fulfilled, rejected } = extraActions.reset;
      return {
          [pending]: (state) => {
              state.error = null;
          },
          [fulfilled]: (state, action) => {
            state.error = action.payload;
          },
          [rejected]: (state, action) => {
              state.error = action.error;
          }
      };
    }
    function updatepass() {
      const { pending, fulfilled, rejected } = extraActions.updatepass;
      return {
          [pending]: (state) => {
              state.error = null;
          },
          [fulfilled]: (state, action) => {
            history.navigate('/login',{ replace: true });
            state.error = action.payload;
          },
          [rejected]: (state, action) => {
              state.error = action.error;
              history.navigate('/login',{ replace: true });
          }
      };
    }
}
